module.exports = [{
      plugin: require('/Users/davidhahn/dev/website/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/davidhahn/dev/website/portfolio/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1100,"quality":90,"linkImagesToOriginal":true},
    },{
      plugin: require('/Users/davidhahn/dev/website/portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-128412557-1","anonymize":true},
    },{
      plugin: require('/Users/davidhahn/dev/website/portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
